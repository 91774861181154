import { getDownloadURL, ref as firebaseRef, uploadBytes } from 'firebase/storage'
import { storage } from '../firebase/config'
import { ref } from 'vue'

const useStorage = () => {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)

  const uploadImage = async (file) => {
    filePath.value = `thumbnails/${file.name}`
    const storageRef = firebaseRef(storage, filePath.value)

    try {
      await uploadBytes(storageRef, file).then(async () => {
        await getDownloadURL(storageRef).then((fileUrl) => {
          url.value = fileUrl          
        })
      })      
    }
    catch (err) {
      console.log(err.message)
      error.value = err.message
    }
  }  

  return { url, filePath, error, uploadImage }
}

export default useStorage