import { ref } from "vue";
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";

const error = ref(null)
const isPending = ref(null)

const login = async (email, password) => {
  error.value = null
  isPending.value = true

  await signInWithEmailAndPassword(auth, email, password).then(() => {
    error.value = null
    isPending.value = false
  }).catch((err) => {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  })
}

const useLogin = () => {
  return { error, isPending, login }
}

export default useLogin