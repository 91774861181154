<template> 
  <NavBar />
  <div class="content">    
    <router-view/>    
  </div>  
  <Footer />
</template>

<script>
  import NavBar from './components/NavBar.vue'
  import Footer from './components/Footer.vue'
  export default {
    components: { NavBar, Footer },
    name: 'Snow Chronicle',
    // metaInfo: {
    //   title: 'Snow Chronicle',
    //   description: 'A blog about skiing adventures and experiments.',
    //   keywords: 'skiing, snow, travel'
    // }
  }
</script>

<style>
  .content {
    margin: 0 auto;
    width: 90vw;
    max-width: 1200px;
    padding: 0 20px;
    min-height: 405px;
  }
</style>
