<template>
  <form @submit.prevent="handleSubmit">
    <h3>Edit post</h3>

    <label for="title">Title</label>
    <input id="title" type="text" required v-model="title">

    <label for="description">Description</label>
    <textarea id="description" v-model="description"></textarea>

    <label for="content">Content</label>
    <quill-editor id="content" :options="options" toolbar="full" v-model:value="content" contentType="html" required />

    <label for="tags">Tags</label>
    <input type="text" id="tags" v-model="tag" @keydown.enter.prevent="addTag">
    <div class="tags">
      <div class="pill" v-for="tag in tags" :key="tag">
        <span @click="deleteTag(tag)">#{{ tag }}</span>
      </div>
    </div>

    <label for="thumbnailFile">Thumbnail</label>
    <input id="thumbnailFile" type="file" @change="handleChange">
    <div class="error">{{ fileError }}</div>

    <div class="error">
      {{ error }}
    </div>

    <button v-if="!isPending">Update post</button>
    <button v-else disabled>Updating...</button>
    <div class="error">
      {{ storageError }}
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import {useRouter} from 'vue-router'

// import quill editor for text
import { quillEditor, Quill } from 'vue3-quill'
import 'quill/dist/quill.snow.css'
import ImageUploader from 'quill-image-uploader'
Quill.register('modules/imageUploader', ImageUploader)

//firebase imports
import { db } from '../firebase/config'
import { getDoc, doc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref as firebaseRef, uploadBytes } from 'firebase/storage'
import { storage } from '../firebase/config'

import getUser from '../composables/getUser'
import useStorage from '../composables/useStorage'

export default {
  components: {
    quillEditor
  },
  props: ['id'],

  setup(props) {
    const modules = {
      name: 'ImageUploader',
      module: ImageUploader
    }

    const { url, filePath, uploadImage, error: storageError } = useStorage()
    const post = ref(null)

    const { user } = getUser()
    const router = useRouter()
    
    const title = ref(null)
    const content = ref(null)
    const description = ref(null)
    const tag = ref('')
    const tags = ref(null)
    const thumbnailFile = ref(null)
    const userIdOfCreator = ref(null)
 
    const error = ref(null)    
    const fileError = ref(null)
    const isPending = ref(false)

    const loadPost = async () => {
      const docRef = doc(db, 'posts', props.id) // doc Id is set to be the title on the Create view.
      const docSnap = await getDoc(docRef).catch((err) => {
        console.log(err.message)
        error.value = err.message
      })

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data())
        title.value = docSnap.data().title
        content.value = docSnap.data().content
        description.value = docSnap.data().description
        tags.value = docSnap.data().tags
        userIdOfCreator.value = docSnap.data().userId
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!")
      }
    }     
    
    loadPost() 

    const options = ref({ 
      theme: 'snow',
      placeholder: 'Start writing ...',
      modules: {
        imageUploader: {
          upload: (file) => {
            return new Promise(async (resolve, reject) => {
              console.log(file)
              const imageFilePath = `${title.value}/${file.name}`
              let imageRef = firebaseRef(storage, imageFilePath)

              try {
                await uploadBytes(imageRef, file).then(async () => {
                  await getDownloadURL(imageRef).then((fileUrl) => {
                    console.log(fileUrl)          
                    resolve(fileUrl)
                  })
                })      
              }
              catch (err) {
                console.log(err.message)              
              }
            })
          }
        }
      }
    })    

    const handleSubmit = async () => {
      if (user.value.uid == userIdOfCreator.value || user.value.uid == '4AFuCHBw1Gb8sQvqqefjG264b7n2') {
        isPending.value = true
        const docRef = doc(db, 'posts', props.id)  
        
        if (thumbnailFile.value) {
          await uploadImage(thumbnailFile.value)
          if (!storageError.value) {
            console.log('Image uploaded, url: ', url.value)

            await updateDoc(docRef, {
              thumbnailFileUrl: url.value,
              filePath: filePath.value
            })
          }
        }

        await updateDoc(docRef, {
          title: title.value,
          content: content.value,
          description: description.value,
          tags: tags.value
        }).then(() => {
          if (!error.value) {
            router.push({ name: 'Post', params: { id: docRef.id } })
          }
          error.value = null
          isPending.value = false
        }).catch((err) => {
          console.log(err.message)
          error.value = err.message
          isPending.value = false
        })
      }
      else {
        console.log('Only the creator of the post can edit.')
      }
      
    }

    const addTag = () => {
      if (!tags.value.includes(tag.value)) {
        tags.value.push(tag.value.replace(/\s/, ''))
      }
      else {
        const message = 'Tag already exists'
        alert(message)
        console.log(message)
      }

      tag.value = ''
    }

    const deleteTag = (tagToDelete) => {
      tags.value = tags.value.filter((item) => {
        return tagToDelete !== item
      })
    }

    // allowed file types
    const allowedTypes = ['image/png', 'image/jpeg']

    const handleChange = (e) => {
      const selected = e.target.files[0]
      console.log(selected)

      if (selected && allowedTypes.includes(selected.type)) {
        thumbnailFile.value = selected
        fileError.value = null
      }
      else {
        thumbnailFile.value = null
        fileError.value = 'Please select an image file (.png or .jpeg)'
      }
    }

    return { options, handleSubmit, title, content, tag, tags, addTag, thumbnailFile, deleteTag, error, handleChange, fileError, storageError, modules, description, post, isPending }
  }
}
</script>

<style scoped>
  h3 {
    margin-bottom: 30px;
  }
  label {
    padding-left: 10px;
  }
  input, textarea {
    margin-bottom: 30px;
  }
  form {
    max-width: 850px;
  }
  .tags {
    display: flex;
    margin-bottom: 20px;
  }
  .pill {
    display: inline-block;
    margin: 0 10px 0 0;
    color: var(--primary);
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
  }
  input#tags {
    margin-bottom: 15px;
  }
</style>