import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { format } from 'date-fns'

const getCollection = (c, q) => {
  const documents = ref(null)

  // collection reference
  let colRef = collection(db, c)

  if (q) { 
    if (q == "sortTime") {
      colRef = query(colRef, orderBy('createdAt', 'desc'))
    }
    else {
      colRef = query(colRef, where(...q), orderBy('createdAt', 'desc'))
    }  
  }  

  const unsub = onSnapshot(colRef, snapshot => {
    let results = []   

    snapshot.docs.forEach(doc => {
      let dateString = (doc.data().createdAt.toDate())
      dateString = (format(dateString, 'dd/MM/yyy'))
      results.push({ ...doc.data(), id: doc.id, createdAt: dateString })
    })

    documents.value = results
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  })

  return { documents }
}

export default getCollection