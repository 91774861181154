<template>
  <form @submit.prevent="handleSubmit">
    <h3>Create new post</h3>

    <label for="title">Title</label>
    <input id="title" type="text" required v-model="title">

    <label for="description">Description</label>
    <textarea id="description" v-model="description"></textarea>

    <label for="content">Content</label>
    <quill-editor id="content" :options="options" toolbar="full" v-model:value="content" contentType="html" required />

    <label for="categories">Categories</label>
    <input type="text" id="categories" v-model="category" @keydown.enter.prevent="addCategory">
    <div class="categories">
      <div class="category" v-for="category in categories" :key="category">
        <span @click="deleteCategory(category)">{{ category }}</span>
      </div>
    </div>

    <label for="tags">Tags</label>
    <input type="text" id="tags" v-model="tag" @keydown.enter.prevent="addTag">
    <div class="tags">
      <div class="pill" v-for="tag in tags" :key="tag">
        <span @click="deleteTag(tag)">#{{ tag }}</span>
      </div>
    </div>

    <label for="thumbnailFile">Thumbnail</label>
    <input id="thumbnailFile" type="file" @change="handleChange">
    <div class="error">{{ fileError }}</div>

    <div class="error">
      {{ error }}
    </div>

    <button v-if="!isPending">Create post</button>
    <button v-else disabled>Creating...</button>
    <div class="error">
      {{ storageError }}
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import {useRouter} from 'vue-router'

// import quill editor for text
import { quillEditor, Quill } from 'vue3-quill'
import 'quill/dist/quill.snow.css'
import ImageUploader from 'quill-image-uploader'
Quill.register('modules/imageUploader', ImageUploader)

//firebase imports
import { db } from '../firebase/config'
import { setDoc, doc, serverTimestamp } from 'firebase/firestore'
import { getDownloadURL, ref as firebaseRef, uploadBytes } from 'firebase/storage'
import { storage } from '../firebase/config'

import getUser from '../composables/getUser'
import useStorage from '../composables/useStorage'

export default {
  components: {
    quillEditor
  },

  setup() {
    const modules = {
      name: 'ImageUploader',
      module: ImageUploader
    }

    const { url, filePath, uploadImage, error: storageError } = useStorage()

    const { user } = getUser()
    const router = useRouter()
    
    const title = ref('')
    const content = ref('')
    const description = ref('')
    const tag = ref('')
    const tags = ref([])
    const category = ref('')
    const categories = ref([])
    const thumbnailFile = ref(null)
 
    const error = ref(null)
    const isPending = ref(false)
    const fileError = ref(null)

    const options = ref({ 
      theme: 'snow',
      placeholder: 'Start writing ...',
      modules: {
        imageUploader: {
          upload: (file) => {
            return new Promise(async (resolve, reject) => {
              console.log(file)
              const imageFilePath = `${title.value}/${file.name}`
              let imageRef = firebaseRef(storage, imageFilePath)

              try {
                await uploadBytes(imageRef, file).then(async () => {
                  await getDownloadURL(imageRef).then((fileUrl) => {
                    console.log(fileUrl)          
                    resolve(fileUrl)
                  })
                })      
              }
              catch (err) {
                console.log(err.message)              
              }
            })
          }
        }
      }
    })

    const handleSubmit = async () => {
      const postId = title.value.replace(/ /g, '-')

      const docRef = doc(db, 'posts', postId)      

      if (thumbnailFile.value) {
        isPending.value = true

        await uploadImage(thumbnailFile.value)
        if (!storageError.value) {
          console.log('Image uploaded, url: ', url.value)          

          await setDoc(docRef, {
            title: title.value,
            content: content.value,
            description: description.value,
            categories: categories.value,
            tags: tags.value,
            createdAt: serverTimestamp(),
            userName: user.value.displayName,
            userId: user.value.uid,
            thumbnailFileUrl: url.value,
            filePath: filePath.value
          }).then(() => {
            if (!error.value) {
              router.push({ name: 'Post', params: { id: docRef.id } })
            }
            error.value = null
            isPending.value = false 
          }).catch((err) => {
            console.log(err.message)
            error.value = err.message
            isPending.value = false
          })
        } 
        else {
          console.log(storageError.value)
          isPending.value = false
        }     
      }      
    }

    const addCategory = () => {
      if (!categories.value.includes(category.value)) {
        categories.value.push(category.value.replace(/\s/, ''))
      }
      else {
        const message = 'category already exists'
        alert(message)
        console.log(message)
      }

      category.value = ''
    }

    const deleteCategory = (category) => {
      categories.value = categories.value.filter((item) => {
        return item !== category
      })
    }

    const addTag = () => {
      if (!tags.value.includes(tag.value)) {
        tags.value.push(tag.value.replace(/\s/, ''))
      }
      else {
        const message = 'Tag already exists'
        alert(message)
        console.log(message)
      }

      tag.value = ''
    }

    const deleteTag = (tag) => {
      tags.value = tags.value.filter((item) => {
        return item !== tag
      })
    }

    // allowed file types
    const allowedTypes = ['image/png', 'image/jpeg']

    const handleChange = (e) => {
      const selected = e.target.files[0]
      console.log(selected)

      if (selected && allowedTypes.includes(selected.type)) {
        thumbnailFile.value = selected
        fileError.value = null
      }
      else {
        thumbnailFile.value = null
        fileError.value = 'Please select an image file (.png or .jpeg)'
      }
    }

    return { options, handleSubmit, title, content, tag, tags, addTag, thumbnailFile, deleteTag, error, 
      isPending, handleChange, fileError, storageError, modules, description, categories, category, addCategory, deleteCategory }
  }
}
</script>

<style scoped>
  h3 {
    margin-bottom: 30px;
  }
  label {
    padding-left: 10px;
  }
  input, textarea {
    margin-bottom: 30px;
  }
  form {
    max-width: 850px;
  }
  .tags, .categories {
    display: flex;
    margin-bottom: 20px;
  }
  .pill, .category {
    display: inline-block;
    margin: 0 10px 0 0;
    color: var(--primary);
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
  }
  input#tags, input#categories {
    margin-bottom: 15px;
  }
</style>