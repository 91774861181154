<template>
  <div class="list">
    <h2>Shed</h2>
    <PostList :posts="documents"/>
  </div>  
</template>

<script>
import PostList from '../components/PostList.vue'
import getCollection from '../composables/getCollection'

export default {
  components: { PostList },

  setup() {
    const { documents } = getCollection('posts', ['categories', 'array-contains', 'shed'])

    return {documents}
  }
}
</script>

<style scoped>
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    align-self: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 15px;
  }
</style>