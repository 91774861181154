<template>
  <article class="about">
    <h1>About</h1>
    <div class="text">
      <p>
        The main idea behind Snow Chronicle is for it to be a place for me to talk about and share my results of working on and tinkering with ski equipment.
      </p>
      <p>
        I remember before experimenting with heat molding my own boots for the first time, I tried searching the internet for things like the temperatures required for heating the plastic, and techniques for stretching the shape and didn't really find a whole lot precise information.
      </p>
      <p>
        I thought it might be good therefore to present my personal findings with a focus on accurately describing the methods and displaying the results.
      </p>
      <p>
        Perhaps I'm also just contributing to the noise but if this information is helpful to anyone else I would be glad.
      </p>
      <p>
        Besides shed talk I chronicle my ski trips and provide some thoughts on the gear I use.
      </p>
      <p>
        I currently live in Melbourne, Australia, so a there is a bit of a focus on skiing and exploring in Victorian mountains. Prior to the pandemic I skied in Japan and Canada and am hoping to make many more overeas ski quests once international travel resumes.
      </p>
      <p class="last">
        Have fun perusing the articles on this site.
      </p>
    </div>  
    <img src="https://firebasestorage.googleapis.com/v0/b/snowchron.appspot.com/o/thumbnails%2Fprofile.jpg?alt=media&token=c73a99fd-a508-4c2a-ae39-188ef5889bdb" alt="Author skiing at Hotham">
  </article>  
</template>

<script>
export default {

}
</script>

<style scoped>
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    padding-bottom: 15px;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 15px;
  }
  .text {
    max-width: 700px;
    margin-top: 5px;
  }
  p {
    margin-bottom: 15px;
    text-align: center, justify;
  }
  .last {
    text-align: center;
  }
  img {
    max-height: 400px;
  }
</style>