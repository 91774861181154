<template>
  <div class="list">
    <h2>Search results for '{{ word }}'</h2>
    <div v-if="allPosts.length">
      <PostList :posts="allPosts"/>
    </div>
    <div v-else class="empty">
      No posts found for '{{ word }}'
    </div>
  </div> 
</template>

<script>
import { db } from '../firebase/config'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { format } from 'date-fns'
import { ref } from '@vue/reactivity'
import PostList from '../components/PostList.vue'
import { computed, watch } from '@vue/runtime-core'

export default {
  props: ['word'],
  components: { PostList },
  setup(props) {
    let colRef = collection(db, 'posts')
    colRef = query(colRef, orderBy('createdAt', 'desc'))
    const allPosts = ref([])
    const currentWord = computed(() => {
      return props.word.toLowerCase()
    })

    const load = async (field) => {
      allPosts.value = []

      const querySnapshot = await getDocs(colRef)
      
      querySnapshot.forEach((post) => {
        let dateString = (post.data().createdAt.toDate())
        dateString = (format(dateString, 'dd/MM/yyy'))

        if (post.data().title.toLowerCase().includes(currentWord.value) ||
        post.data().description.toLowerCase().includes(currentWord.value) ||
        post.data().tags.includes(props.word)) {          
          allPosts.value.push({ ...post.data(), id: post.id, createdAt: dateString })
        }             
      })      
    }  

    load()

    watch(currentWord, () => {
      console.log('Change')
      load()
      console.log(currentWord)
      console.log(allPosts)
    })
    

    return { allPosts }
  }

}
</script>

<style scoped>
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    padding-bottom: 15px;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 15px;
  }
  .empty {
    margin-top: 10px;
  }
</style>