<template>
  <div class="footer">
    <div class="links">
      <router-link :to="{name: 'Home'}">
        <span class="left">Home</span>
      </router-link> 
      <router-link :to="{ name: 'About' }">
        <span>About</span>
      </router-link> 
      <router-link :to="{ name: 'Contact' }">
        <span class="right">Contact</span>
      </router-link>      
    </div>
    <div class="logo">
      <img src="@/assets/anzus1.png" alt="rune logo">
      <p>Rune 2022</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 10px auto 0;
    padding-bottom: 20px;
  }
  img {
    max-height: 40px;   
    display: inline-block; 
  }
  p {
    display: inline-block;
  }
  .links {
    max-width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px auto 0;
    padding: 10px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    border-top: 2px solid #efefef;
    background: #ebf9ff;
  }
  span {
    cursor: pointer;
    transition: all ease 0.2s;
    padding: 0 15px 0;
  }
  span:hover {
    color: #8d92a7;
    transition: all ease 0.2s;
  }
  .left {
    border-left: 2px solid var(--primary);
  }
  .right {
    border-right: 2px solid var(--primary);
  }
</style>