<template>
  <form @submit.prevent="handleLogin">
    <h3>Login</h3>
    <input type="email" placeholder="Email" v-model="email" required>
    <input type="password" placeholder="Password" v-model="password" required> 

    <button v-if="!isPending">Login</button>
    <button v-if="isPending" disabled>Loading</button>
    <div v-if="error" class="error">{{ error }}</div>
  </form>
</template>

<script>
import { ref } from '@vue/reactivity'
import { auth } from '../firebase/config'
import { useRouter } from 'vue-router'
import useLogin from '../composables/useLogin'

export default {
  setup() {
    const { error, isPending, login } = useLogin()
    const router = useRouter()

    const email = ref('')    
    const password = ref('')

    const handleLogin = async () => {
      await login(email.value, password.value)
      if (!error.value) {
        console.log(auth.currentUser)
        router.push({ name: 'Home' })
      } 
    }

    return { error, isPending, handleLogin, email, password }
  }
}
</script>

<style scoped>
  button {
    margin-top: 25px;
  }
</style>