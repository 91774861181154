<template>
  <div class="home">    
    <PostList :posts="homePosts" />
    <div class="all" @click="handleViewAll">view all</div>
  </div>
</template>

<script>
import getUser from '../composables/getUser'
import getCollection from '../composables/getCollection'
import PostList from '../components/PostList.vue'
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { useRouter } from 'vue-router'

export default {
  name: 'Home',
  components: { PostList },
  // metaInfo: {
  //   title: 'Home',  
  // },
  
  setup() {
    const { user } = getUser()
    const { documents } = getCollection('posts', 'sortTime')
    const router = useRouter()

    const homePosts = ref(null)
    watch(documents, newValue => {
      if ( newValue?.length > 5) {
        homePosts.value = newValue.slice(0, 5)
      }
      else {
        homePosts.value = newValue
      }
    })    

    const allPosts = ref(null)
    watch(documents, posts => {
      allPosts.value = posts
    })

    const handleViewAll = () => {      
      router.push({ name: 'AllPosts' })
    }   

    return { user, homePosts, handleViewAll }
  }
}
</script>

<style scoped>
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div.all {
    margin: 0 auto;
    padding: 10px 0 0;
    display: block;
    text-align: center;
    transition: all ease 0.2s; 
    font-size: 1.2em;
  }
  div.all:hover {
    color: var(--light-blue);
    transition: all ease 0.2s;  
    cursor: pointer;
  }
</style>
