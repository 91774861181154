<template>
  <div class="header">
    <nav>
      <router-link class="ico" :to="{ name: 'Home' }">
        <img src="@/assets/snowchron-icon-large-no-background.png">
        <h1>Snow Chronicle</h1>
      </router-link> 
      <div class="right">
        <input type="text" placeholder="Search" v-model="searchWord" @keydown.enter.prevent="handleSearch">
        <div v-if="user">   
          <span>Logged in as {{ user.displayName }}</span> 
          <span class="logout" @click="handleLogout">Logout</span>
        </div>
      </div>  
    </nav>
  </div>
  <div class="navbar">
    <div class="links">
      <div class="topics">        
        <router-link :to="{ name: 'Trips' }">
          <div class="topic">Trips</div>
        </router-link>
        <router-link :to="{ name: 'Shed' }">
          <div class="topic central">Shed</div>
        </router-link>
        <router-link :to="{ name: 'Reviews' }">
          <div class="topic">Reviews</div>
        </router-link>
      </div>
      <router-link v-if="user" :to="{ name: 'Create' }">
        <div class="create">Create</div>
      </router-link>      
    </div>    
  </div>
</template>

<script>
import getUser from '../composables/getUser'
import useLogout from '../composables/useLogout'
import { useRouter } from 'vue-router'
import { ref } from '@vue/reactivity'
export default {
  setup() {
    const { user } = getUser()
    const router = useRouter()

    const { logout } = useLogout()

    const searchWord = ref(null)

    const handleLogout = async () => {
      logout()
      router.push({ name: 'Login' })
    }

    const handleSearch = () => {
      if (searchWord.value) {
        console.log('Search is yet to be implemented')
        router.push({ name: 'Search', params: { word: searchWord.value } })
        searchWord.value = null
      }
    }

    return { user, handleLogout, searchWord, handleSearch }
  }
}
</script>

<style scoped>
  .header {
    padding: 5px 10px 5px;
    background: #aae5ff;
    border-bottom: 2px solid #bfc6db;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 80%;
    margin: 0 auto;
  }
  .ico {
    display: flex;
    align-items: center;
  }
  img {
    max-height: 70px;
  }
  h1 {
    margin-top: 10px;
    font-size: 4vw;
  }
  .navbar {
    background: #ebf9ff;
    margin-bottom: 30px;
    border-bottom: 2px solid #efefef;
  }
  .links {
    max-width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .topics { 
    display: flex;
    margin: 0 auto 0 70px;
  }
  .topic {
    margin: 12px 0;
    padding: 3px 10px;
    display: inline-block;
  }
  .central {
    border-left: 2px solid #b9d2f8;
    border-right: 2px solid #b9d2f8;
  }
  span {
    font-size: 14px;
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;      
  }
  span.logout {
    transition: all ease 0.2s;
    border-left: 1px solid var(--primary);
  }
  span.logout:hover, .topic:hover {
    color: #8d92a7;
    transition: all ease 0.2s;  
    cursor: pointer;    
  }
  .create {
    margin-left: auto;
    margin-right: 70px;
    transition: all ease 0.2s; 
  }
  .create:hover {
    color: var(--light-blue);
    transition: all ease 0.2s;  
    cursor: pointer;
  }
  input {
    border-radius: 15px;
    border: 1px solid var(--light-blue);
    max-width: 170px;
  }
  .right {
    display: flex;
    align-items: center;
  }
</style>