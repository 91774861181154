<template>
  <div v-if="error">{{ error }}</div>
  <div class="post" v-if="post">
    <h2>{{ post.title }}</h2>
    <router-link v-if="ownership" :to="{ name: 'Edit', params: { id: id } }">
      <span class="edit">Edit</span>
    </router-link>
    <div class="info">
      <span class="author">Written by {{ post.userName }}</span>
      <span>{{ post.createdAt }}</span>
    </div>    
    <div class="main" v-html="post.content"></div>
    <div class="tag-container">
      <div class="post-tags" v-for="tag in post.tags" :key="tag">
        #{{ tag }}
      </div>
    </div>    
  </div>
  <div v-else>
    Loading
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore"
import { db } from '../firebase/config'
import { ref, computed, onMounted, onUpdated } from 'vue'
import { format } from 'date-fns'
import getUser from '../composables/getUser'

export default {
  name: 'Post',  
  props: ['id'],
  
  setup(props) {
    const post = ref(null)
    const error = ref(null) 
    const { user } = getUser()

    const loadPost = async () => {
      const docRef = doc(db, 'posts', props.id) // doc Id is set to be the title on the Create view.
      const docSnap = await getDoc(docRef).catch((err) => {
        console.log(err.message)
        error.value = err.message
      })

      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data())
        let dateString = (docSnap.data().createdAt.toDate())
        dateString = (format(dateString, 'dd/MM/yyy'))
        post.value = { ...docSnap.data(), createdAt: dateString }        
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!")
      }
    }

    loadPost(scrollTo) 

    const ownership = computed(() => {      
      return post.value && user.value && (post.value.userId == user.value.uid || user.value.uid == '4AFuCHBw1Gb8sQvqqefjG264b7n2')
    })

    onMounted(() => {
      document.documentElement.scrollTop = 0      
    })

    onUpdated(() => {
      if (window.matchMedia('only screen and (max-width: 768px)').matches) {
        const images = document.images
        for (let image of images) {
          console.log(image.classList)
          image.style.maxWidth = '300px'
        }
      }
    })    

    return { post, error, ownership }
  }
}
</script>

<style scoped>
  .post {
    display: flex;
    flex-direction: column;
  }
  h2 {
    align-self: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #cccccc;
  }
  .info {
    display: flex;
  }
  .author {
    border-right: 1px solid var(--primary);
    padding-right: 10px;
    margin-right: 0;
  }
  .tag-container {
    align-self: center;
    margin-top: 15px;
  }  
  .post-tags {
    display: inline-block;
    padding: 10px;
  }
  .main {
    padding: 15px 10px 15px;
    display: flex;    
    flex-direction: column;
    border-bottom: 2px solid #cccccc;
  }
  span {
    margin: 10px;    
  }
  a {
    margin: 10px 0;
  }
  a span {
    text-decoration: underline;
  }
</style>