<template>
  <div class="list">
    <h2>{{ category }}</h2>
    <PostList :posts="posts"/>
  </div>  
</template>

<script>
import PostList from '../components/PostList.vue'
import getCollection from '../composables/getCollection'
import { computed, onMounted, onUpdated, ref } from 'vue'

export default {
  props: ['category'],
  components: { PostList },

  setup(props) {
    const posts = computed(() => {
      const { documents } = getCollection('posts', ['categories', 'array-contains', `${props.category}`])
      return documents.value
    })



    // onUpdated(() => {
    //   const { documents } = getCollection('posts', ['categories', 'array-contains', `${props.category}`])
    //   posts.value = documents.value
    //   console.log(documents.value, 'Hello this has updated')
    // })

    return { posts }
  }

}
</script>

<style scoped>
  .list {
    display: flex;
    flex-direction: column;
  }
  h2 {
    align-self: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 15px;
  }
</style>