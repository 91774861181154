<template>
  <form @submit.prevent="handleSignup">
    <h3>Sign up</h3>
    <input type="email" placeholder="Email" v-model="email" required>
    <input type="text" placeholder="Display name" v-model="displayName" required>
    <input type="password" placeholder="Password" v-model="password" required> 

    <button v-if="!isPending">Sign up</button>
    <button v-if="isPending" disabled>Loading</button>
    <div v-if="error" class="error">{{ error }}</div>
  </form>
</template>

<script>
import { ref } from '@vue/reactivity'
import useSignup from '../composables/useSignup'
import { auth } from '../firebase/config'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const { error, isPending, signup } = useSignup()
    const router = useRouter()

    const entryPassword = prompt('Please enter the password', '')
    if (entryPassword != 'keywaxchalk') {
      router.push({ name: 'Home' })
    }

    const email = ref('')
    const displayName = ref('')
    const password = ref('')

    const handleSignup = async () => {
      await signup(email.value, password.value, displayName.value)
      if (!error.value) {
        console.log(auth.currentUser)
        router.push({ name: 'Home' })
      } 
    }

    return { error, isPending, handleSignup, email, displayName, password }
  }
}
</script>

<style scoped>
  button {
    margin-top: 25px;
  }
</style>