import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase/config'
import Home from '../views/Home.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
import Create from '../views/Create.vue'
import AllPosts from '../views/AllPosts.vue'
import Post from '../views/Post.vue'
import Edit from '../views/Edit.vue'
import About from '../views/About.vue'
import Trips from '../views/Trips.vue'
import Shed from '../views/Shed.vue'
import Reviews from '../views/Reviews.vue'
import Contact from '../views/Contact.vue'
import CategoryPosts from '../views/CategoryPosts.vue'
import Search from '../views/Search.vue'

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if ( !user ) {
    next({ name: 'Home' })
  }
  else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/create',
    name: 'Create',
    component: Create,
    beforeEnter: requireAuth
  },
  {
    path: '/posts/all',
    name: 'AllPosts',
    component: AllPosts,
    props: true
  },
  {
    path: '/posts/:id',
    name: 'Post',
    component: Post,
    props: true
  },
  {
    path: '/posts/edit/:id',
    name: 'Edit',
    component: Edit,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/posts/trips',
    name: 'Trips',
    component: Trips
  },
  {
    path: '/posts/shed',
    name: 'Shed',
    component: Shed
  },
  {
    path: '/posts/reviews',
    name: 'Reviews',
    component: Reviews
  },
  {
    path: '/posts/:category',
    name: 'Category',
    component: CategoryPosts,
    props: true
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/search/:word',
    name: 'Search',
    component: Search,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
