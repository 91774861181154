// firebase imports
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";

const logout = async () => {
  await signOut(auth).catch((error) => {
    console.log(error.message)
  })
}

const useLogout = () => {
  return { logout }
}

export default useLogout