<template>
  <div class="main">
    <h2>All Posts</h2>
    <PostList :posts="documents" />
  </div>  
</template>

<script>
import getCollection from '../composables/getCollection'
import PostList from '../components/PostList.vue'
import {onMounted, ref} from 'vue'

export default {
  components: { PostList },

  setup(props) {
    const { documents } = getCollection('posts', 'sortTime')
    console.log(props.title)

    onMounted(() => {
      document.documentElement.scrollTop = 0
    })
    
    return { documents }
  }
}
</script>

<style scoped>  
  h2 {
    align-self: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 15px;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>