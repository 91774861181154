import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyCngQmFI-QEa_MMgxBucUOkuu_u70PwdX0",
  authDomain: "snowchron.firebaseapp.com",
  projectId: "snowchron",
  storageBucket: "snowchron.appspot.com",
  messagingSenderId: "474190224716",
  appId: "1:474190224716:web:c3411f65a918e80b7083e9",
  measurementId: "G-DCE657K3DQ"
}

// init firebase
const firebaseApp = initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()
const storage = getStorage(firebaseApp)
const analytics = getAnalytics(firebaseApp)

export { db, auth, storage, analytics }