<template>
  <article class="about">
    <h1>Contact</h1>
    <div class="text">  
      <p>Snow Chronicle is created by James Bedford</p>    
      <p>
        If you would like to get in touch, send me an email at james@snowchron.com
      </p>      
    </div>  
  </article>  
</template>

<script>
export default {

}
</script>

<style scoped>
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    padding-bottom: 15px;
    border-bottom: 2px solid #cccccc;
    margin-bottom: 15px;
  }
  .text {
    max-width: 700px;
    margin-top: 50px;
  }
  p {
    margin-bottom: 15px;
    text-align: center;
  }
</style>