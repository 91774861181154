<template>
  <div class="container">    
    <div v-for="post in posts" :key="post.id" :class="[mobile ? 'single-post-mobile': 'single-post']">
      <router-link :to="{name: 'Post', params: { id: post.id }}">
        <div class="post-content" :class="[mobile ? 'post-content-mobile' : 'post-content']">
          <img :src="post.thumbnailFileUrl" :class="[mobile ? 'img-mobile' : 'img']">
          <div :class="[mobile ? 'info-mobile' : 'info']">
            <h3 :class="{'title-mobile': mobile}">{{ post.title }}</h3>
            <div class="time">{{ post.createdAt }}</div>
            <p :class="[mobile ? 'description-mobile' : 'description']">{{ post.description }}</p>
            <div class="tags">
              <div class="tag" v-for="tag in post.tags" :key="tag">#{{ tag }}</div>
            </div>
          </div>
        </div>          
      </router-link>  
    </div>    
  </div>
  
</template>

<script>
import { ref } from 'vue'
export default {
    props: ['posts'],
    setup(props) {
      const mobile = ref(window.matchMedia('only screen and (max-width: 768px)').matches)

      return { mobile }
    }
}
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .single-post {
    height: 200px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    border: 1px solid var(--light-blue);
    transition: all ease 0.2s;
  }
  .single-post, .single-post-mobile:hover {
    box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
    transform: scale(1.02);
    transition: all ease 0.2s;
  }
  .single-post-mobile {
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    border: 1px solid var(--light-blue);
    transition: all ease 0.2s;
    display: flex;
  }
  .post-content {
    display: flex;
  }
  .post-content-mobile {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
  .title-mobile {
    text-align: center;
  }
  .img {
    max-height: 200px;
    border-radius: 5px;
    margin-right: 20px;
  }
  .img-mobile {
    border-radius: 5px;
    max-height: 20vh;
  }
  .info {
    display: flex;
    flex-direction: column;
  }
  .info-mobile {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  .description {
    height: 100px;
    margin-top: 10px;
  }
  .description-mobile {
    margin: 10px 0 10px 0;
  }
  .tag {
    display: inline-block;
    margin: 0 10px 0 0;
    color: var(--primary);
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
  }
  .time {
    margin-top: 10px;
  }
</style>